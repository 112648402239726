<div class="container-fluid" style="margin-top: 60px;">
  <div *ngIf="!createdSale || isEiku; then posView else saleView"></div>
</div>

<ng-template #posView>

  <!-- adding items -->
  <div class="row mt-4 gutter-b" >
    <div class="col">
      <div class="card">

        <div class="card-header">
          <div class="d-flex">
            <div class="mb-4">
              <h1>{{'common.sales'|translate}}</h1>
            </div>

            <!-- store choice and selection / only if multistore -->
            <div *ngIf="multistore" class="ml-4 mb-4">
              <div *ngIf="store">
                <label ngbButtonLabel class="d-flex btn-info p-1">
                  <span class="badge badge-light mb-0">{{'common.store'|translate|titlecase}}</span>
                  <h2 class="mx-2 mb-0">{{store.name}}</h2>
                  <span class="badge badge-secondary p-1 ml-1 mb-0" (click)="unselectStore()"><i-bs name="x"></i-bs></span>
                </label>
              </div>
              <div *ngIf="!store" class="btn-group btn-group-toggle">
                <label *ngFor="let s of stores" ngbButtonLabel class="p-1 btn-outline-secondary">
                  <input type="checkbox" ngbButton id="{{s.id}}"
                    [(ngModel)]="storeChoice[s.id]"
                    (change)="storeChosen($event)"/>
                  <h1 class="mx-1">{{s.name}}</h1>
                </label>
              </div>
            </div>

            <!-- latest sale -->
            <div class="ml-auto" *ngIf="latestSale && latestSale.created_at && foundProducts.length===0 && selectedProducts.length===0">
              <h3>
                {{'common.latest_sale'|translate}}
                <span class="badge badge-light ml-2">{{latestSale.created_at | date : 'd.M. HH:mm'}}</span>
                <span class="badge badge-light ml-2">{{latestSale.total | currency}}</span>
              </h3>
            </div>
          </div>

          <!-- store selection warning -->
          <div *ngIf="!store" class="d-flex">
            <h4 class="alert alert-warning">{{'merchant.pos.select_store'|translate}}</h4>
          </div>

          <!-- barcode and product entry -->
          <div *ngIf="store" class="d-flex justify-content-between">
            <div>
              <form [formGroup]='barcodeScanForm' (ngSubmit)="submitBarcodeScan()">
                <div class="form-group">
                  <div class="input-group mr-2">
                    <input #barcodeInput class="scanned-number form-control" type="text" formControlName="scanned_number" placeholder="{{'common.read_barcode'|translate}}" autofocus/>
                  </div>
                </div>
              </form>
            </div>
            <div class="alert alert-info align-self-start px-3 py-1" *ngIf="productAlreadySelected">
              <span class="mr-2">{{'common.product_is_already_selected'|translate}}</span>
              <button class="btn btn-secondary p-1 m-0" (click)="clearProductNotice()"><i-bs name="x" class="p-0 m-0"></i-bs></button>
            </div>
            <div class="alert alert-warning align-self-start px-3 py-1" *ngIf="productNotFound">
              <span class="mr-2">{{'common.not_found'|translate}}</span>
              <span *ngIf="unfoundBarcode" class="mr-2"><b>{{unfoundBarcode}}</b></span>
              <button class="btn btn-secondary p-1 m-0" (click)="clearProductNotice()"><i-bs name="x" class="p-0 m-0"></i-bs></button>
            </div>
            <div class="alert alert-warning align-self-start px-3 py-1" *ngIf="unsellableProductFound">
              <span class="mr-2">{{'common.found_unsellable'|translate}}</span>
              <span class="badge badge-light mr-2">{{'product_status.'+unsellableStatus|translate|uppercase}}</span>
              <button class="btn btn-secondary p-1 m-0" (click)="clearProductNotice()"><i-bs name="x" class="p-0 m-0"></i-bs></button>
            </div>
            <div>
              <form [formGroup]='productSearchForm' (ngSubmit)="submitProductSearch()">
                <div class="form-group">
                  <div class="input-group mr-2">
                    <input class="seller-number form-control" type="text" formControlName="seller_number" placeholder="{{'common.seller_number'|translate|titlecase}}"/>
                    <input class="product-number form-control mr-2" type="text" formControlName="product_number" placeholder="{{'common.product_number'|translate|titlecase}}"/>
                    <button class="btn btn-primary" [disabled]="productNotFound">{{'common.find'|translate|uppercase}}</button>
                    <button class="btn btn-outline-secondary ml-2" (click)="unfindAll()" [disabled]="!foundProducts || foundProducts.length==0">{{'common.close_find'|translate|uppercase}}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="card-body" *ngIf="store && foundProducts && foundProducts.length > 0">
          <div class="container-fluid">

            <!-- display found product -->
            <div class="row">
              <div *ngIf="foundProducts.length==0; else foundProductTable"><h4>{{'common.no_products'|translate}}</h4></div>
              <ng-template #foundProductTable>
                <table class="table table-sm table-hover">
                  <thead>
                    <tr>
                      <th class="col-md-x2">{{'common.number'|translate|titlecase}}</th>
                      <th class="col-md-x2">{{'common.type'|translate|titlecase}}</th>
                      <th class="col-md-x2">{{'common.brand'|translate|titlecase}}</th>
                      <th class="col-md-x2">{{'common.size'|translate|titlecase}}</th>
                      <th class="col-md-x2">{{'common.note'|translate|titlecase}}</th>
                      <th class="col-md-x2">{{'common.price'|translate|titlecase}}</th>
                      <!-- <th class="col-md-x2">{{'common.condition'|translate|titlecase}}</th> -->
                      <th class="col-md-x3"><!-- actions --></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of foundProducts" [ngClass]="{'table-light':isSelected(product)}">
                      <td class="col-md-x2" scope="row"><span class="badge badge-light">{{zeroPad6(product.sales_period.seller_id)}}-{{zeroPad6(product.number)}}</span><span *ngIf="product.is_manual" class="badge badge-light ml-1">M</span></td>

                      <td class="col-md-x2"><product-feature-name [product]="product" feature="type"></product-feature-name></td>
                      <td class="col-md-x2"><product-feature-name [product]="product" feature="brand"></product-feature-name></td>
                      <td class="col-md-x2"><product-feature-name [product]="product" feature="size"></product-feature-name></td>

                      <td class="col-md-x2"><p><span *ngIf="!product.is_manual; else noInfo">{{product.note}}</span></p></td>
                      <td class="col-md-x2"><p><span *ngIf="!product.is_manual; else noInfo">{{product.price | currency}}</span></p></td>
                      <ng-template #noInfo>&mdash;</ng-template>

                      <td class="col-md-x3 action-buttons">
                        <div class="d-flex">
                          <div class="ml-auto">
                            <div *ngIf="!isSelected(product); else productIsAlreadySelected">
                              <button class="btn btn-sm btn-primary p-2 m-0" (click)="select(product)">{{'common.select'|translate|uppercase}}</button>
                              <!-- <button class="btn btn-sm btn-outline-light p-2 m-0" (click)="unfind(product)"><i-bs name="x"></i-bs></button> -->
                            </div>
                            <ng-template #productIsAlreadySelected>
                              <button disabled class="btn btn-sm btn-outline px-1 py-1 mx-1">{{'common.selected_already'|translate|uppercase}}</button>
                            </ng-template>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- accumulated items -->
  <div *ngIf="store" class="row" >
    <div class="col">
      <div class="card">
        <div class="card-header">
          <div class="d-flex">
            <div><h1 *ngIf="isEiku">{{'merchant.pos.eiku_title'|translate}}</h1></div>
            <div class="ml-auto mr-5"><h2><span class="badge badge-info">{{sum()}}&nbsp;€</span></h2></div>
            <div><button class="btn btn-primary pt-2 pb-1" [disabled]="!mayProceedToKassa()" (click)="kassalle()"><h3>{{'merchant.pos.checkout'|translate|uppercase}}</h3></button></div>
          </div>
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">

              <div class="col-md-12">
                <div *ngIf="selectedProducts.length==0; else selectedProductTable"><h3>{{'common.no_products'|translate}}</h3></div>
                <ng-template #selectedProductTable>
                  <table class="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th class="col-md-x2">{{'common.number'|translate|titlecase}}</th>
                        <th class="col-md-x2">{{'common.type'|translate|titlecase}}</th>
                        <th class="col-md-x2">{{'common.brand'|translate|titlecase}}</th>
                        <th class="col-md-x2">{{'common.size'|translate|titlecase}}</th>
                        <th class="col-md-x2">{{'common.note'|translate|titlecase}}</th>
                        <th class="col-md-x2">{{'common.price'|translate|titlecase}}</th>
                        <th class="col-md-x2"></th><!-- other store alert -->
                        <th class="col-md-x2"></th>
                        <th class="col-md-x2"><!-- actions --></th>
                      </tr>
                    </thead>
                      <tbody>
                        <tr *ngFor="let product of selectedProducts">
                          <td class="col-md-x2" scope="row"><span class="badge badge-light">{{zeroPad6(product.sales_period.seller_id)}}-{{zeroPad6(product.number)}}</span><span *ngIf="product.is_manual" class="badge badge-light ml-1">M</span></td>

                          <td class="col-md-x2"><product-feature-name [product]="product" feature="type"></product-feature-name></td>
                          <td class="col-md-x2"><product-feature-name [product]="product" feature="brand"></product-feature-name></td>
                          <td class="col-md-x2"><product-feature-name [product]="product" feature="size"></product-feature-name></td>

                          <td class="col-md-x2"><p><span>{{product.note}}</span></p></td>
                          <td class="col-md-x2"><p><span *ngIf="!product.is_manual; else manualPrice">{{priceDisplay(product)}}</span></p></td>
                          <ng-template #manualPrice><span *ngIf="product.pos_set_price; else noManualPrice">{{priceDisplay(product)}}</span></ng-template>
                          <ng-template #noManualPrice><span class="badge badge-warning">?</span></ng-template>

                          <td class="col-md-x2"><span *ngIf="product.store.id != store.id" class="badge badge-warning">{{product.store.name}}</span></td><!-- other store alert -->
                          <td class="col-md-x2">
                            <span class="p2 badge badge-success" *ngIf="product.to_be_added">{{'common.to_be_added'|translate|uppercase}}</span>
                            <span class="p2 badge badge-warning" *ngIf="product.to_be_removed">{{'common.to_be_removed'|translate|uppercase}}</span>
                            <span class="p2 badge badge-info" *ngIf="isEiku && (!product.to_be_removed && !product.to_be_added)">{{'common.sold'|translate|uppercase}}</span>
                          </td>
                          <td class="col-md-x2 action-buttons">
                            <div class="d-flex">
                              <div class="ml-auto">
                                <button *ngIf="product.is_manual" class="btn btn-sm btn-primary px-1 py-1 ml-1 mr-3" (click)="specifyPrice(product)">{{'merchant.pos.specify_price'|translate|uppercase}}</button>
                                <button *ngIf="product.to_be_added || product.to_be_removed" class="btn btn-sm btn-secondary px-1 py-1 mx-1" (click)="cancelEikuAddOrRemove(product)">{{'common.cancel'|translate|uppercase}}</button>
                                <button *ngIf="!product.deleting && !product.to_be_removed && !product.to_be_added" class="btn btn-sm btn-outline-danger px-1 py-1 mx-1" (click)="remove(product)">{{'common.remove'|translate|uppercase}}</button>
                                <button *ngIf="product.deleting" class="btn btn-sm btn-outline-danger px-1 py-1 mx-1"><span class="spinner-border spinner-border-sm mr-1"></span></button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </ng-template>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #saleView>
  <div class="jumbotron">
    <div class="d-flex">
      <div>
        <h1 class="display-4">{{'merchant.pos.created_sale.header'|translate|uppercase}} &bull; {{store.name}}</h1>
        <p class="lead">ALV 24%</p>
      </div>
      <div class="ml-auto" style="text-align: right;">
        <h1 class="ml-auto"><span class="badge badge-success" style="min-width: 7em;">{{createdSale.total|currency}}</span></h1>
      </div>
    </div>
    <div class="d-flex">
      <div><button class="btn btn-warning" (click)="eiku()">{{'common.eiku'|translate|uppercase}}</button></div>
      <div class="ml-auto"><button class="btn btn-primary" (click)="next()">{{'common.next'|translate|uppercase}}</button></div>
    </div>
    <hr class="my-4">

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th class="col-md-x1">{{'common.number'|translate|titlecase}}</th>
                <th class="col-md-x3">{{'common.description'|translate|titlecase}}</th>
                <th class="col-md-x2">{{'common.price'|translate|titlecase}}</th>
                <th class="col-md-x2">{{'common.store'|translate|titlecase}}</th>
                <!-- <th class="col-md-x2">{{'common.condition'|translate|titlecase}}</th> -->
              </tr>
            </thead>
              <tbody>
                <tr *ngFor="let row of createdSale.sale_rows">
                  <td class="col-md-x1" scope="row"><span class="badge badge-light">{{row.product_number}}</span><span *ngIf="row.product_is_manual" class="badge badge-light ml-1">M</span></td>
                  <td class="col-md-x3"><p>{{row.description}}</p></td>
                  <td class="col-md-x2"><p>{{saleRowPriceDisplay(row)}}</p></td>
                  <td class="col-md-x2">
                    <span *ngIf="row.product_store.id == store.id" class="badge badge-light">{{row.product_store.name}}</span>
                    <span *ngIf="row.product_store.id != store.id" class="badge badge-warning">{{row.product_store.name}}</span>
                  </td>
                </tr>
              </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #priceEntryModal let-modal>
  <form [formGroup]="priceEntryForm" (onSubmit)="submitPrice()">
    <div class="modal-header">
      <!-- <h1>{{'merchant.pos.specify_price_title' | translate:{pn:pn()} }}</h1> -->
      <h1>{{ pn() }}</h1>
    </div>
    <div class="modal-body">
      <input type="number" formControlName="price" class="form-control" style="font-size: 200%"/>
    </div>
    <div class="modal-footer d-flex">
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel')">{{'common.cancel'|translate|uppercase}}</button>
      <button type="submit" class="btn btn-primary ml-auto" (click)="modal.close('ok')">{{'common.save'|translate|uppercase}}</button>
    </div>
  </form>
</ng-template>

import { Component, Input } from '@angular/core'

import { ProductFeaturesService } from '@/services/product-features.service'

import { Product } from '@/domain/product'
import { Observable } from 'rxjs'

@Component({
  selector: 'product-feature-name',
  templateUrl: 'product-feature-name.component.html',
  styleUrls: [ ]
})
export class ProductFeatureNameComponent {
  @Input() product!: Product
  @Input() feature!: 'brand' | 'type' | 'size'

  constructor(
    private features: ProductFeaturesService,
  ) {}

  public featuresById$(): Observable<{}> {
    switch (this.feature) {
      case 'brand':
        return this.features.brandsById$
      case 'type':
        return this.features.typesById$
      case 'size':
        return this.features.sizesById$
    }
  }

  public featureId(): number {
    switch (this.feature) {
      case 'brand':
        return this.product.product_brand_id || 0
      case 'type':
        return this.product.product_type_id || 0
      case 'size':
        return this.product.product_size_id || 0
    }
  }

  get featureCustomName(): string {
    switch (this.feature) {
      case 'brand':
        return this.product.custom_product_brand
      case 'type':
        return this.product.custom_product_type
      case 'size':
        return this.product.custom_product_size
    }
  }
}
<span *ngIf="product.is_manual; else show">&mdash;</span>

<ng-template #show>
  <span *ngIf="featureId() != 0; else custom">
    {{
      (featuresById$()|async)[featureId()]
        ? (featuresById$()|async)[featureId()].name
        : '??'
    }}
  </span>
</ng-template>

<ng-template #custom>
  {{ featureCustomName || '—' }}<!-- mdash -->
</ng-template>

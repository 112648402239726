import { registerLocaleData } from '@angular/common'
import localeFi from '@angular/common/locales/fi'
import { AppRoutingModule } from '@/app-routing.module'
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, DEFAULT_CURRENCY_CODE } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight'
import { NgSelectModule } from '@ng-select/ng-select'
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { OrderModule } from 'ngx-order-pipe'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { NgxIbanModule } from 'ngx-iban'
import { ActionCableService } from 'angular2-actioncable'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
// import { NgNumericKeyboardModule } from 'ng-numeric-keyboard'

import {
  arrowDownShort,
  arrowUpShort,
  boxArrowRight,
  boxArrowDown,
  boxArrowUpRight,
  calendar3,
  calendarDate,
  caretDown,
  check2,
  checkSquare,
  chevronDoubleDown,
  chevronDoubleUp,
  fileText,
  list,
  pencil,
  pencilSquare,
  person,
  plus,
  plusSquare,
  printer,
  save,
  trash,
  x,
  xSquare
} from 'ngx-bootstrap-icons'

import { AppComponent } from '@/app.component'

import { ApiCallInterceptor } from '@/util/api-call.interceptor'
import { JwtInterceptor } from '@/util/auth/jwt.interceptor'
import { ErrorInterceptor } from '@/util/error.interceptor'
import { fakeBackendProvider } from './util/auth/fake-backend'

import { ActiveSellersComponent } from '@/ui/merchant/sellers/active-sellers/active-sellers.component'
import { AlertComponent } from '@/ui/common/components/alert.component'
import { AllSellersComponent } from '@/ui/merchant/sellers/all-sellers/all-sellers.component'
import { ApplicationUpdateAlertComponent } from '@/ui/common/components/application-update-alert.component'
import { CancelledSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/cancelled-sales-periods.component'
import { ConfirmComponent } from '@/ui/common/auth/confirm.component'
import { CurrentSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/current-sales-periods.component'
import { FooterComponent } from '@/ui/common/layout/footer/footer.component'
import { FutureSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/future-sales-periods.component'
import { HeaderComponent } from '@/ui/common/layout/header/header.component'
import { HomeComponent } from '@/ui/home/home.component'
import { LoginComponent } from '@/ui/common/login/login.component'
import { MerchantCalendarViewComponent } from '@/ui/merchant/calendar/merchant-calendar-view.component'
import { MerchantDashboardViewComponent } from '@/ui/merchant/dashboard/merchant-dashboard-view.component'
import { MerchantPosComponent } from '@/ui/merchant/pos/merchant-pos.component'
import { MerchantProfileComponent } from '@/ui/common/components/merchant/merchant-profile.component'
import { MerchantSellerViewComponent } from '@/ui/merchant/sellers/merchant-seller-view.component'
import { NgbdSortableHeader } from '@/ui/common/util/ngbd-things'
import { PastSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/past-sales-periods.component'
import { PendingSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/pending-sales-periods.component'
import { PreliminarySalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/preliminary-sales-periods.component'
import { ProductBrandComponent } from '@/ui/merchant/product-features/brand/brand.component'
import { ProductCategoryComponent } from '@/ui/merchant/product-features/category/product-category.component'
import { ProductColorComponent } from '@/ui/merchant/product-features/color/color.component'
import { ProductConditionComponent } from '@/ui/merchant/product-features/condition/condition.component'
import { ProductFeatureNameComponent } from '@/ui/common/product/product-feature-name.component'
import { ProductFeaturesComponent } from '@/ui/merchant/product-features/common/product-features.component'
import { ProductFeatureSidebarComponent } from '@/ui/merchant/product-features/common/sidebar/sidebar.component'
import { ProductsComponent } from '@/ui/merchant/service/products/products.component'
import { ProductSizeComponent } from '@/ui/merchant/product-features/size/size.component'
import { ProductStatusComponent } from '@/ui/merchant/product-features/status/product-status.component'
import { ProductTableComponent } from '@/ui/common/product/product-table.component'
import { ProductTypeComponent } from '@/ui/merchant/product-features/type/type.component'
import { RackListComponent } from '@/ui/merchant/racks/rack-list/rack-list.component'
import { RegisterComponent } from '@/ui/common/register/register.component'
import { RejectedSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/rejected-sales-periods.component'
import { RequestPasswordComponent } from '@/ui/common/password/request-password.component'
import { ResetPasswordComponent } from '@/ui/common/password/reset-password.component'
import { SalesPeriodBookingHeaderComponent } from '@/ui/common/components/sales-period/sales-period-booking-header.component'
import { SalesPeriodCalendarComponent } from '@/ui/common/components/sales-period/sales-period-calendar.component'
import { SalesPeriodSellerReservationComponent } from '@/ui/common/components/sales-period/sales-period-seller-reservation.component'
import { SalesPeriodComponent } from '@/ui/common/components/sales-period/sales-period.component'
import { SalesPeriodEditModalComponent } from '@/ui/common/components/sales-period/sales-period-edit-modal.component'
import { SalesPeriodHeaderComponent } from '@/ui/common/components/sales-period/sales-period-header.component'
import { SalesPeriodListComponent } from '@/ui/common/components/sales-period/sales-period-list.component'
import { SalesPeriodStatusBadgeComponent } from '@/ui/common/components/sales-period-status-badge.component'
import { SalesPeriodsSummaryListComponent } from '@/ui/common/components/sales-period/sales-periods-summary-list.component'
import { SalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/sales-periods-view.component'
import { SalesPeriodTableComponent } from '@/ui/common/sales-period/sales-period-table.component'
import { SalesPeriodViewComponent } from '@/ui/common/sales-period/sales-period-view.component'
import { SellerBookingViewComponent } from '@/ui/seller/booking/seller-booking-view.component'
import { SellerProfileComponent } from '@/ui/common/components/seller/seller-profile.component'
import { SepaXmlViewComponent } from '@/ui/merchant/settlements/sepa-xml-view.component'
import { ServiceLevelsComponent } from '@/ui/merchant/service/levels/service-levels.component'
import { ServiceProductSelectorComponent } from '@/ui/common/components/service-product/service-product-selector.component'
import { ServiceProductsViewComponent } from '@/ui/merchant/service/service-products-view.component'
import { ServiceTypesComponent } from '@/ui/merchant/service/types/service-types.component'
import { SettingsViewComponent } from '@/ui/common/settings/settings-view.component'
import { SettlementsViewComponent } from '@/ui/merchant/settlements/settlements-view.component'
import { StoreSelectorComponent } from '@/ui/common/components/store/store-selector.component'
import { SignupComponent } from '@/ui/common/signup/signup.component'
import { UnsettledSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/unsettled-sales-periods.component'
import { WebpackTranslateLoader } from '@/util/webpack-translate-loader'

import { YSortPipe } from '@/ui/common/pipes/ysort-pipe'

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

registerLocaleData(localeFi)

const icons = {
  arrowDownShort,
  arrowUpShort,
  boxArrowRight,
  boxArrowDown,
  boxArrowUpRight,
  calendar3,
  calendarDate,
  caretDown,
  check2,
  checkSquare,
  chevronDoubleDown,
  chevronDoubleUp,
  fileText,
  list,
  pencil,
  pencilSquare,
  person,
  plus,
  plusSquare,
  printer,
  save,
  trash,
  x,
  xSquare
}

@NgModule({
  declarations: [
    ActiveSellersComponent,
    AlertComponent,
    AllSellersComponent,
    AppComponent,
    ApplicationUpdateAlertComponent,
    CancelledSalesPeriodsViewComponent,
    ConfirmComponent,
    CurrentSalesPeriodsViewComponent,
    FooterComponent,
    FutureSalesPeriodsViewComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    MerchantCalendarViewComponent,
    MerchantDashboardViewComponent,
    MerchantPosComponent,
    MerchantProfileComponent,
    MerchantSellerViewComponent,
    NgbdSortableHeader,
    PastSalesPeriodsViewComponent,
    PendingSalesPeriodsViewComponent,
    PreliminarySalesPeriodsViewComponent,
    ProductBrandComponent,
    ProductCategoryComponent,
    ProductColorComponent,
    ProductConditionComponent,
    ProductFeaturesComponent,
    ProductFeatureNameComponent,
    ProductFeatureSidebarComponent,
    ProductsComponent,
    ProductSizeComponent,
    ProductStatusComponent,
    ProductTableComponent,
    ProductTypeComponent,
    RackListComponent,
    RegisterComponent,
    RejectedSalesPeriodsViewComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    SalesPeriodBookingHeaderComponent,
    SalesPeriodCalendarComponent,
    SalesPeriodComponent,
    SalesPeriodEditModalComponent,
    SalesPeriodHeaderComponent,
    SalesPeriodListComponent,
    SalesPeriodSellerReservationComponent,
    SalesPeriodsSummaryListComponent,
    SalesPeriodStatusBadgeComponent,
    SalesPeriodsViewComponent,
    SalesPeriodTableComponent,
    SalesPeriodViewComponent,
    SellerBookingViewComponent,
    SellerProfileComponent,
    SepaXmlViewComponent,
    ServiceLevelsComponent,
    ServiceProductSelectorComponent,
    ServiceProductsViewComponent,
    ServiceTypesComponent,
    SettingsViewComponent,
    SettlementsViewComponent,
    StoreSelectorComponent,
    SignupComponent,
    UnsettledSalesPeriodsViewComponent,
    YSortPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgSelectModule,
    NgOptionHighlightModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxIbanModule,
    // NgNumericKeyboardModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fi',
      loader: { provide: TranslateLoader, useClass: WebpackTranslateLoader }
    }),
    OrderModule,
    NgxBootstrapIconsModule.forRoot(icons),
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    })
  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },

    { provide: HTTP_INTERCEPTORS, useClass: ApiCallInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    ActionCableService,
    fakeBackendProvider
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

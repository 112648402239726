import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import * as api from '@/config/api-config.json'

import { SalesPeriodsService } from './sales-periods.service'

import { Product } from '@/domain/product'
import { Store } from '@/domain/store'

@Injectable({ providedIn: 'root' })
export class ProductsService {

  constructor(
    private _salesPeriodsService: SalesPeriodsService,
    private _http: HttpClient
  ) { }

  getPriceTagForProduct(productId: number): Observable<Blob> {
    let endpoint = `${api.priceTags.getForProduct}/${productId}`
    return this._http.get(endpoint, { responseType: "blob", headers: { 'Accept': 'application/pdf' }})
  }

  searchPos(store: Store, values): Observable<Product[]> {
    let requestBody_ = {
      "product": {
        "store_id": store.id,
        "seller_number": values.seller_number,
        "product_number": values.product_number
      }
    }
    return this._http
      .post<Product[]>(api.products.searchForPos, requestBody_)
      .pipe<Product[]>(catchError(this._handleError))
  }

  updateProductStatus(status: string, productIds: number[]) {
    let requestBody = {
      "product": {
        "status": status,
        "product_ids": productIds
      }
    }
    return this._http
      .post<Product>(api.products.statusUpdate, requestBody)
      .pipe<Product>(catchError(this._handleError))
  }

  createManualProducts(count: number, sales_period_id: number): Observable<Product[]> {
    return this._http
      .post<Product[]>(api.products.createManual, { 'sales_period_id': sales_period_id, 'count': count })
      .pipe<Product[]>(catchError(this._handleError))
  }

  saveOrCreate(salesPeriodId: number, values, productId: number): Observable<Product> {
    let price = values.price ? values.price.replace(',', '.') : null
    let requestBody = {
      "product_type_id": values.type_id,
      "product_brand_id": values.brand_id,
      "product_size_id": values.size_id,
      "price": price,
      "note": values.note
    }

    if (values.custom_product_brand) { requestBody['custom_product_brand'] = values.custom_product_brand }
    if (values.custom_product_type) { requestBody['custom_product_type'] = values.custom_product_type }
    if (values.custom_product_size) { requestBody['custom_product_size'] = values.custom_product_size }

    if (productId) {
      return this._http
        .patch<Product>(`${api.products.create}/${productId}`, { 'product': requestBody })
        .pipe<Product>(catchError(this._handleError))
    } else {
      requestBody["sales_period_id"] = salesPeriodId
      return this._http
        .post<Product>(api.products.create, { 'product': requestBody })
        .pipe<Product>(catchError(this._handleError))
    }
  }

  delete(productId: number): Observable<Product> {
    return this._http
      .delete<Product>(`${api.products.delete}/${productId}`)
      .pipe(map(deletedProduct => {
        this._salesPeriodsService.reload(deletedProduct.sales_period_id)
        return deletedProduct
      }), catchError(this._handleError))
  }

  private _handleError(error: HttpErrorResponse) {
    return throwError(error)
  }
}

<div class="card gutter-b" *ngIf="period">
  <div class="card-header">
    <sales-period-header
      [period]="period"
      [showNotes]="true">
    </sales-period-header>
    <div class="d-flex mt-4">
      <button *ngIf="mayAddProducts" class="btn btn-primary mr-2" (click)="addProducts()" [disabled]="isCreating || isEditing || (auth.isSeller&&period.status=='status_cancelled')"><i-bs name="plusSquare" class="mr-2"></i-bs> {{'common.add_products'|translate|uppercase}}</button>
      <button *ngIf="mayAddProducts" class="btn btn-primary mr-2" (click)="maybeConfirm(confirmationModal)" [disabled]="!hasAnyPricesToConfirm() || (auth.isSeller&&period.status=='status_cancelled')"><i-bs name="checkSquare" class="mr-2"></i-bs> {{'common.finalize_pricing'|translate|uppercase}} <span class="badge badge-success p-1 m-0 ml-2" *ngIf="period.product_counts['initial']>0">{{period.product_counts['initial']}}</span></button>
      <button *ngIf="auth.isMerchant" class="btn btn-primary mr-2" [disabled]="!mayPrint()" (click)="maybeDownloadPriceTags(priceTagPrintingModal)"><i-bs name="printer" class="mr-2"></i-bs> {{'common.print'|translate|uppercase}} <span class="badge badge-success p-1 m-0 ml-2" *ngIf="mayPrint()">{{period.product_counts['confirmed']}}</span></button>
      <button *ngIf="auth.isMerchant" class="btn btn-primary mr-2" (click)="openSalePeriodModal()"><i-bs name="pencil"></i-bs></button>
      <div class="ml-auto">
        <a *ngIf="hasReceiptPdf()" role="button" class="btn btn-primary mr-2" [href]="receiptPdfUrl()" target="_blank">
          {{'sales_period.receipt_pdf'|translate|uppercase}}
          <i-bs name="boxArrowDown" class="ml-1"></i-bs>
        </a>
        <button class="btn btn-primary" (click)="viewSettlements(settlementsModal)">
          {{'common.settlements'|translate|uppercase}}
          <span *ngIf="auth.isMerchant && period.unsettled_products_count>0" class="badge badge-info m-0 p-1 ml-2">{{period.unsettled_products_count}}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <ng-container *ngIf="isCreating || isEditing; then productEditingTemplate"></ng-container>
    <product-table [period]="period" (edit)="edit($event)"></product-table>
  </div>
  <div class="card-footer" *ngIf="auth.isMerchant">
    <button class="btn btn-warning" (click)="maybeCancel(salesPeriodCancellationModal)" [disabled]="period.status=='status_cancelled'">{{'common.cancel_sales_period'|translate|uppercase}}</button>
  </div>
</div>

<ng-template #productEditingTemplate>
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-xs-12 col-md-12">
        <div class="card">
          <form [formGroup]="productForm" (ngSubmit)="save()" *ngIf="isCreating || isEditing">
            <table class="table">
              <thead>
                <tr class="d-flex">
                  <th class="col-2">{{'common.type'|translate|titlecase}}</th>
                  <th class="col-2">{{'common.brand'|translate|titlecase}}</th>
                  <th class="col-2">{{'common.size'|translate|titlecase}}</th>
                  <th class="col-2" *ngIf="mayAddProductNote">{{'common.product_note'|translate|titlecase}}</th>
                  <th class="col-1">{{'common.price'|translate|titlecase}}</th>
                  <th class="col-3"></th>
                </tr>
              </thead>
              <tbody *ngIf="(features.types$|async) && (features.brands$|async) && (features.sizes$|async)">
                <tr class="d-flex">
                  <td class="col-2">
                    <ng-select #productTypeSelect formControlName="type_id" autofocus placeholder="{{'common.choose_type'|translate}}"
                      [items]="features.types$|async" bindLabel="name" bindValue="id" [selectOnTab]=true [searchFn]="searchWithSynonyms"
                      (change)="productBrandSelect.focus()" [virtualScroll]="true">
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span [ngOptionHighlight]="search">{{item.name}}</span>
                      </ng-template>
                    </ng-select>
                    <input type="text" formControlName="custom_product_type" tabindex="0" placeholder="{{'merchant.pricing.new_type'|translate}}" class="form-control" *ngIf="mayCreateFeatures"/>
                  </td>
                  <td class="col-2">
                    <ng-select #productBrandSelect formControlName="brand_id" placeholder="{{'common.choose_brand'|translate}}"
                      [items]="features.brands$|async" bindLabel="name" bindValue="id" [selectOnTab]=true [searchFn]="searchWithSynonyms"
                      (change)="productSizeSelect.focus()" [virtualScroll]="true">
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span [ngOptionHighlight]="search">{{item.name}}</span>
                      </ng-template>
                    </ng-select>
                    <input type="text" formControlName="custom_product_brand" tabindex="0" placeholder="{{'merchant.pricing.new_brand'|translate}}" class="form-control" *ngIf="mayCreateFeatures"/>
                  </td>
                  <td class="col-2">
                    <ng-select #productSizeSelect formControlName="size_id" placeholder="{{'common.choose_size'|translate}}"
                      [items]="features.sizes$|async" bindLabel="name" bindValue="id" [selectOnTab]=true [searchFn]="searchWithSynonyms"
                      (change)="productPriceInput.focus()" [virtualScroll]="true">
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span [ngOptionHighlight]="search">{{item.name}}</span>
                      </ng-template>
                    </ng-select>
                    <input type="text" formControlName="custom_product_size" tabindex="0" placeholder="{{'merchant.pricing.new_size'|translate}}" class="form-control" *ngIf="mayCreateFeatures"/>
                  </td>
                  <td class="col-2" *ngIf="mayAddProductNote">
                    <input #productNoteInput formControlName="note" type="text" class="form-control"/>
                  </td>
                  <td class="col-2">
                    <input #productPriceInput formControlName="price" type="text" class="form-control"/>
                  </td>
                  <td class="col-2">
                    <input type="submit" class="btn btn-primary p-2 mr-2" [disabled]="!productForm.valid" value="{{'common.save'|translate|uppercase}}" #submitProductInput/>
                    <input type="button" class="btn btn-secondary p-2" (click)="cancel()" value="{{'common.close'|translate|uppercase}}"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div class="card mt-4 p-3" *ngIf="auth.isMerchant">
          <form [formGroup]="manualProductForm" (ngSubmit)="addManualProducts()">
            <div class="manualProductsAddWrapper" style="display: inline-flex;">
              <input [type]="'number'" formControlName="manual_products_count" class="form-control"/>
              <button type="submit" class="btn btn-primary ml-2" style="white-space: nowrap;">{{'common.add_manual_products'|translate|uppercase}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #settlementsModal let-modal>
  <div class="modal-header">
    <!-- <div class="d-flex"> -->
      <h1 class="modal-title" id="modal-basic-title">
        {{'common.settlements'|translate|titlecase}}
        <span *ngIf="auth.isMerchant && period.unsettled_products_count > 0">
          &bull;
          {{'merchant.salesperiod.need_to_be_settled'|translate|titlecase}}
          <span class="badge badge-info p-2 ml-1">{{period.unsettled_products_count}}</span>
        </span>
      </h1>
      <div *ngIf="auth.isMerchant" class="ml-auto">
        <div *ngIf="period.unsettled_products_count>0; else allSettled">
          <button class="btn btn-primary" (click)="settle(false, settlementConfirmationModal)">
            {{'merchant.settlements.settle_for_merchant'|translate|uppercase}}
            <!-- <span class="badge badge-info m-0 p-1 ml-2">{{period.unsettled_products_count}}</span> -->
          </button>
          <button class="btn btn-primary ml-2" (click)="settle(true, settlementConfirmationModal)">
            {{'merchant.settlements.settle_for_seller'|translate|uppercase}}
            <!-- <span class="badge badge-info m-0 p-1 ml-2">{{period.unsettled_products_count}}</span> -->
          </button>
        </div>
        <ng-template #allSettled>
          <h1 class="modal-title">{{'merchant.settlements.nothing_to_settle'|translate}}</h1>
        </ng-template>
      </div>
    <!-- </div> -->
    <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button> -->
  </div>
  <div class="modal-body">
    <alert></alert>

    <table class="table table-sm" *ngIf="(settlements$|async)&& (settlements$|async).length > 0; else noSettlements">
      <thead>
        <th>{{'common.num_short'|translate|titlecase}}</th>
        <th>{{'common.date_short'|translate|titlecase}}</th>
        <th>{{'common.sales'|translate|titlecase}}</th>
        <th>{{'common.settlement_service_charges'|translate|titlecase}}</th>
        <th>{{'common.to_settle'|translate|titlecase}}</th>
        <th>PDF</th>
        <th *ngIf="auth.isMerchant">{{'common.settled_for'|translate|titlecase}}</th>
        <th>{{'common.settlement_method'|translate|titlecase}}</th>
        <th *ngIf="auth.isMerchant">{{'common.is_paid'|translate|titlecase}}</th>
        <th *ngIf="auth.isMerchant"><!--actions--></th>
      </thead>
      <tbody>
        <tr *ngFor="let settlement of settlements$|async">
          <td><span class="badge badge-light">{{settlement.number}}</span></td>
          <td>{{asDate(settlement.created_at)}}</td>
          <td><span class="price">{{settlement.sales_total | currency}}</span></td>
          <td>
            <span *ngIf="settlement.settlement_service_charges.length > 0; else noServiceCharges">
              <span class="price">{{settlement.service_charges_total | currency}}</span>
              ({{'common.incl_tax'|translate}}&nbsp;<span class="price">{{settlement.service_charges_tax_total | currency}}</span>)
              <span *ngFor="let charge of settlement.settlement_service_charges" style="font-size: 85%; ">
                <br/>{{charge.description}} {{charge.amount|currency}} ({{charge.tax_name}} {{charge.tax_percentage}}%)
              </span>
            </span>
            <ng-template #noServiceCharges></ng-template>
          </td>
          <td><span class="price">{{settlement.settlement_total | currency}}</span></td>
          <td>
            <a role="button" class="btn btn-sm btn-primary p-1" [href]="pdfPath(settlement)" target="_blank" *ngIf="hasPdf(settlement)"><i-bs name="fileText"></i-bs></a>
            <button class="btn btn-sm btn-outline-light p-1" *ngIf="!hasPdf(settlement)"><i-bs name="fileText"></i-bs></button>
          </td>
          <td *ngIf="auth.isMerchant">
            <span class="badge" [ngClass]="{'badge-light':settlement.settled_for==='settled_for_seller', 'badge-warning':settlement.settled_for==='settled_for_merchant'}">
              {{'common.settled_for_' + (settlement.settled_for==='settled_for_seller' ? 'seller' : 'merchant')|translate|uppercase}}
            </span>
          </td>
          <td><!-- settlement method -->
            <span class="badge badge-light">{{'settlement_method.'+settlement.settlement_method|translate|uppercase}}</span>
          </td>
          <td *ngIf="auth.isMerchant">
            <div *ngIf="settlement.settled_for==='settled_for_seller'">
              <span *ngIf="settlement.payment_status === 'payment_status_paid'" class="badge badge-success">{{settlement.payment_time | date: 'yyyy-MM-dd HH:mm'}}</span>
              <div *ngIf="settlement.payment_status === 'payment_status_none'">
                <span class="badge badge-warning">{{'common.no'|translate|uppercase}}</span>
                <button class="btn btn-primary px-2 py-1 m-0 ml-2" (click)="markSettlementPaid(settlement)">{{'merchant.salesperiod.mark_settlement_paid'|translate|uppercase}}</button>
              </div>
            </div>
          </td>
          <td *ngIf="auth.isMerchant">
            <span *ngIf="mayDeleteSettlement(settlement); else noDeleteSettlement">
              <button class="btn btn-sm btn-outline-danger px-1 py-1 mx-1" (click)="maybeDeleteSettlement(settlement, confirmSettlementDeletionModal)"><i-bs name="x"></i-bs></button>
            </span>
            <ng-template #noDeleteSettlement>
              <span>
                <button [disabled]="true" class="btn btn-sm btn-outline-light px-1 py-1 mx-1"><i-bs name="x"></i-bs></button>
              </span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template #noSettlements>
      <span *ngIf="(settlements$|async) && (settlements$|async).length == 0">{{'merchant.settlements.none'|translate}}</span>
    </ng-template>

  </div>
  <div class="modal-footer">
    <input type="button" class="btn btn-secondary" (click)="modal.close()" value="{{'common.close'|translate|uppercase}}"/>
    <!-- <input type="submit" class="btn btn-primary" value="{{'common.save'|translate|uppercase}}"/> -->
  </div>
</ng-template>

<ng-template #settlementConfirmationModal let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      <span *ngIf="settlingForSeller">{{'common.settle_confirmation_and_choice'|translate}}</span>
      <span *ngIf="!settlingForSeller">{{'common.confirmation'|translate}}</span>
      &bull;
      {{'merchant.settlements.confirm_for_' + (settlingForSeller ? 'seller' : 'merchant')|translate}}
    </h1>
  </div>

  <div class="modal-body">
    <div *ngIf="settlingForSeller && (!period.seller.iban || period.seller.iban.trim().length === 0)" class="modal-body">
      <span class="badge badge-warning p-2 mr-1">{{'common.attention'|translate|uppercase}}</span>
      {{'merchant.settlements.warn_no_seller_iban'|translate}}
    </div>
    <div *ngIf="settlingForSeller">
      <div *ngIf="settlementServiceCharges.length > 0 || addingSettlementServiceCharge">
        <form [formGroup]="settlementServiceChargeForm" (ngSubmit)="saveCharge()">
          <h4>{{'common.settlement_service_charges'|translate|titlecase}}</h4>
          <table class="table table-sm">
            <thead>
              <th>{{'common.name'|translate|titlecase}}</th>
              <th>{{'common.price'|translate|titlecase}}</th>
              <th>{{'common.tax_name'|translate|titlecase}}</th>
              <th>{{'common.tax_percentage'|translate|titlecase}}</th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let charge of settlementServiceCharges">
                <td>{{charge.description}}</td>
                <td>{{charge.amount|currency}}</td>
                <td>{{charge.tax_name}}</td>
                <td>{{charge.tax_percentage}}</td>
                <td><button class="btn btn-sm btn-outline-danger px-1 py-1 mx-1" (click)="deleteCharge(charge)"><i-bs name="x"></i-bs></button></td>
              </tr>
              <tr *ngIf="addingSettlementServiceCharge">
                <td><input type="text" formControlName="description" class="form-control"></td>
                <td><input type="text" formControlName="amount" class="form-control"></td>
                <td><input type="text" formControlName="tax_name" class="form-control"></td>
                <td><input type="text" formControlName="tax_percentage" class="form-control"></td>
                <td><input type="submit" class="btn btn-primary p-2 mr-2" [disabled]="!settlementServiceChargeForm.valid" value="{{'common.save'|translate|uppercase}}"/></td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <input type="button" class="btn btn-primary" (click)="createCharge()" value="{{'common.add_settlement_service_charge'|translate|uppercase}}"/>
    </div>
  </div>

  <div class="modal-footer d-flex">
    <div>
      <button class="btn btn-secondary ml-auto" (click)="modal.dismiss('cancel')">{{'common.cancel'|translate|uppercase}}</button>
    </div>
    <div class="ml-auto">
      <div *ngIf="settlingForSeller; else settleForMerchantButtons">
        <button class="btn btn-primary" (click)="modal.close('method_cash')" [disabled]="addingSettlementServiceCharge">{{'common.settle_by_cash'|translate|uppercase}}</button>
        <button class="btn btn-primary ml-2" (click)="modal.close('method_transfer')" [disabled]="addingSettlementServiceCharge || (!period.seller.iban || period.seller.iban.trim().length === 0)">{{'common.settle_by_transfer'|translate|uppercase}}</button>
      </div>
      <ng-template #settleForMerchantButtons>
        <button class="btn btn-primary" (click)="modal.close()">{{'common.confirm'|translate|uppercase}}</button>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #confirmationModal let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      <span *ngIf="auth.isSeller">{{'seller.pricing.confirm_finalization_title'|translate}}</span>
      <span *ngIf="!auth.isSeller">{{'merchant.pricing.confirm_finalization_title'|translate}}</span>
    </h1>
  </div>
  <div class="modal-body">
    <p *ngIf="auth.isSeller">{{'seller.pricing.confirm_finalization_msg'|translate}}</p>
    <p *ngIf="!auth.isSeller">{{'merchant.pricing.confirm_finalization_msg'|translate}}</p>
  </div>
  <div class="modal-footer d-flex">
    <button class="btn btn-secondary" (click)="modal.dismiss('cancel')">{{'common.cancel'|translate|uppercase}}</button>
    <button class="btn btn-primary ml-auto" (click)="modal.close('confirmed')">{{'common.confirm'|translate|uppercase}}</button>
  </div>
</ng-template>

<ng-template #confirmSettlementDeletionModal let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      <span>{{'merchant.settlements.confirm_deletion_title'|translate}}</span>
    </h1>
  </div>
  <div class="modal-body">
    <p *ngIf="settelementForDeletionConfirmation && hasServiceCharges(settelementForDeletionConfirmation)">{{'merchant.settlements.confirm_deletion_msg_has_charges'|translate}}</p>
    <p *ngIf="settelementForDeletionConfirmation && !hasServiceCharges(settelementForDeletionConfirmation)">{{'merchant.settlements.confirm_deletion_msg_no_charges'|translate}}</p>
  </div>
  <div class="modal-footer d-flex">
    <button class="btn btn-secondary" (click)="modal.dismiss('cancel')">{{'common.cancel'|translate|uppercase}}</button>
    <button class="btn btn-primary ml-auto" (click)="modal.close('confirmed')">{{'common.confirm'|translate|uppercase}}</button>
  </div>
</ng-template>

<ng-template #priceTagPrintingModal let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      {{'merchant.pricing.confirm_printing_title'|translate}}
      <span *ngIf="maybePrintingTagsForSalesPeriod">
        <br/>
        {{'product_status.confirmed'|translate|titlecase}} <span class="badge badge-info p-2">{{printingConfirmedCount}}</span> &bull;
        {{'product_status.tagged'|translate|titlecase}} <span class="badge badge-info p-2">{{printingPrintedCount}}</span>
      </span>
    </h1>
  </div>
  <div class="modal-body">
    <p *ngIf="printingConfirmedCount==0 && printingPrintedCount==0; else confirmation">{{'merchant.pricing.none_to_print'|translate}}</p>
    <ng-template #confirmation>
      <p><b>{{'merchant.pricing.confirm_printing_msg'|translate}}</b></p>
      <p>{{'merchant.pricing.confirm_printing_msg2'|translate:{initial:printingInitialCount, printed:printingPrintedCount, other:printingOtherCount} }}</p>
    </ng-template>
  </div>
  <div class="modal-footer">
    <div class="d-flex align-items-start" style="width: 100%;" *ngIf="printingConfirmedCount>0 || printingPrintedCount>0; else justClose">
      <button class="btn btn-secondary" (click)="modal.dismiss('cancel')">{{'common.cancel'|translate|uppercase}}</button>
      <div class="ml-auto" style="text-align: right;">
        <button class="btn btn-primary ml-2 mb-2" (click)="modal.close('confirmed')" *ngIf="printingConfirmedCount>0">{{'merchant.pricing.print_confirmed'|translate|uppercase}} <span class="badge badge-info p-1 m-0 ml-2">{{printingConfirmedCount}}</span></button>
        <button class="btn btn-primary ml-2 mb-2" (click)="modal.close('tagged')" *ngIf="printingPrintedCount>0">{{'merchant.pricing.print_tagged'|translate|uppercase}} <span class="badge badge-info p-1 m-0 ml-2">{{printingPrintedCount}}</span></button>
      </div>
    </div>
    <ng-template #justClose>
      <button class="btn btn-secondary" (click)="modal.dismiss()">{{'common.close'|translate|uppercase}}</button>
    </ng-template>
  </div>
</ng-template>

<ng-template #salesPeriodCancellationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <!-- {{'common.confirm'|translate|titlecase}} -->
      {{'merchant.salesperiod.confirm_cancellation'|translate:{id: period.id} }}
    </h4>
  </div>
  <!-- <div class="modal-body">
    {{'merchant.salesperiod.confirm_cancellation'|translate:{id: period.id} }}
  </div> -->
  <div class="modal-footer d-flex">
    <button class="btn btn-secondary" (click)="modal.dismiss('cancel')">{{'common.cancel'|translate|uppercase}}</button>
    <button class="btn btn-primary ml-auto" (click)="modal.close('confirmed')">{{'common.confirm'|translate|uppercase}}</button>
  </div>
</ng-template>

<sales-period-edit-modal
  [open]="openSalesPeriodModal"
  [(period)]="period"
  (closed)="salesPeriodModalClosed($event)"
  [link]="false"
  >
</sales-period-edit-modal>

